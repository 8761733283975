import { NgModule } from '@angular/core';
import { HashPipe } from './hash.pipe';
import { IconCryptoPipe } from './icon-crypto.pipe';
import { CoinNumberPipe } from './coin-number.pipe';
import { SearchPipe } from './search.pipe';
import { EmailToNamePipe } from './email-to-name.pipe';
import { GetFirstWord } from './first-word.pipe';
import { OrderByPipe } from './order-by.pipe';


@NgModule({
    imports: [],
    exports: [
        HashPipe,
        IconCryptoPipe,
        CoinNumberPipe,
        SearchPipe,
        EmailToNamePipe,
        GetFirstWord,
        OrderByPipe
    ],
    declarations: [
        HashPipe,
        IconCryptoPipe,
        CoinNumberPipe,
        SearchPipe,
        EmailToNamePipe,
        GetFirstWord,
        OrderByPipe
    ],
    providers: [],
})
export class SharedPipeModule { }
