import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'iconCrypto'
})
export class IconCryptoPipe implements PipeTransform {
    transform(value: string): string {
        return crypto_icon[value];
    }
}
const crypto_icon = {
    'ETH': '/assets/images/icon-eth.svg',
    'ALA': '/assets/images/icon-ala.svg',
    'USDT': '/assets/images/icon-usdt.svg'
}