import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/services/account.service';
import { AppUtils } from 'src/app/utils/app.utils';

import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  user: any;
  
  constructor(config: NgbDropdownConfig,
    private accountService: AccountService,
    private router : Router,
    
    private translateService: TranslateService
    ) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.accountService.getUserCacheInfo.then(result => { this.user = result });
    if(localStorage.getItem("serverAdmin") == 'vietnam'){
      this.translateService.setDefaultLang('vn');
    } else {
      this.translateService.setDefaultLang('en');
    }
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  toggleRightSidebar() {
    // document.querySelector('#right-sidebar').classList.toggle('open');
  }

  logout() {
    AppUtils.removeCookie();
    window.localStorage.removeItem('serverAdmin');
    setTimeout(() => {
      AppUtils.removeCookie();
      this.router.navigate(['/']);
    }, 2000);
  }

}
