import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hash'
})
export class HashPipe implements PipeTransform {

    transform(value: string): string {
        if (value) {
            let hash = `${value.substring(0, 8)}....${value.substring(value.length - 8, value.length)}`;
            return hash;
        } else return ''


    }

}
