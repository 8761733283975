import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import Pusher from 'pusher-js';

@Injectable({
    providedIn: 'root',
})
export class PusherService {
    private subject: Subject<any> = new Subject<any>();
    private user: any;
    private pusherClient: Pusher;

    constructor(
        private accountService: AccountService
    ) {
        this.pusherClient = new Pusher('cd457dcf246f96abd25a', { cluster: 'ap1' });
        const allChannel = this.pusherClient.subscribe('all');
        allChannel.bind('my-event', result => {
            this.subject.next(result);
        });
        this.accountService.getUserCacheInfo.then(result => {
            this.user = result;
            const userChannel = this.pusherClient.subscribe(this.user.id + "");
            userChannel.bind('my-event', result => {
                this.subject.next(result);
            }
            );
        });

    }

    getFeedItems(): Observable<any> {
        return this.subject.asObservable();
    }
}