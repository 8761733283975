import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AppUtils } from 'src/app/utils/app.utils';

import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public postCollapsed = false; 
  public realEstateCollapsed = false;
  public userCollapsed = false;
  public newsCollapsed = false;
  public digitalRealEstateCollapsed = false;
  public notifyToEmailCollapsed = false;

  user: any;
  constructor(
    private accountService: AccountService,
    private router: Router,

    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const body = document.querySelector('body');
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
    this.accountService.getUserCacheInfo.then(result => { this.user = result });

    if(localStorage.getItem("serverAdmin") == 'vietnam'){
      this.translateService.setDefaultLang('vn');
    } else {
      this.translateService.setDefaultLang('en');
    }
  }
  

}
