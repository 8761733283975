import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    if(localStorage.getItem("serverAdmin") == 'vietnam'){
      this.translateService.setDefaultLang('vn');
    } else {
      this.translateService.setDefaultLang('en');
    }
  }

}
