import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord'
})
export class GetFirstWord implements PipeTransform
{
    transform(value: string): string | boolean
    {
        if (value == null) {
          return value;
        }
        const words = value.split(' ')[0];
        return words.length > 0 ? words[0] : words;
    }
}
