import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtils } from '../utils/app.utils';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  

  constructor(
    public httpClient: HttpClient
  ) { }

  get requestHeaders() {
    return this.createAPIHeader();
  }
  get requestToken() {
    return "DIASPLAT-ACCESS-KEY="+AppUtils.getToken();
  }
  
  private createAPIHeader() {
    let token = AppUtils.getToken();    
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    });
  }

}
