// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let serverAdmin = localStorage.getItem("serverAdmin");
let environment = {
  production: false,
  // DOMAIN : "http://localhost:9090/api/admin"
  DOMAIN : "https://dev.pindias.com/api/admin"
  // DOMAIN : "http://54.255.230.222/api/admin"
};
if(serverAdmin == "vietnam"){
  environment = {
    production: false,
    // DOMAIN : "http://localhost:9090/api/admin"
    DOMAIN : "https://vn.pindias.com/api/admin"
  };
}else{
  environment = {
    production: false,
  
    // DOMAIN : "http://localhost:9090/api/admin"
    DOMAIN : "https://dev.pindias.com/api/admin"
    // DOMAIN : "http://54.255.230.222/api/admin"
  };
}

export {environment}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
