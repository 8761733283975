import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'coinNumber'
})
export class CoinNumberPipe implements PipeTransform {

  transform(value: number): string {
    if (value == undefined) {
      return '0';
    }
    var dec = 8;
    for (var i = 0; i <= 8; i++) {
      var n = Math.pow(10, i);
      if (!((value * n) % 1)) {
        dec = i;
        break;
      }
    }
    return (formatNumber(value,'en-US', '1.0-'+dec));

    // return value.toString().replace(/^[+-]?\d+/, (int) => {
    //   console.log("int:", int);
    //   return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    // })
  }
}
