import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailOfName'
})
export class EmailToNamePipe implements PipeTransform {

  transform(value: string): any {
    var username = '';
    if (value) {
      username = value.split('@')[0];
    }
    return username;
  }

}
